import React from 'react';

import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.container}>@ 2022 - Georgios Katsanakis (A.K.A KronosGR)</div>
  );
};

export default Footer;
